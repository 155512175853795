import React, { useEffect, Component, useState } from "react"
import dateFormat from 'dateformat';


const PropertyDetails = (props) => {

    const [property_data, setPropertyData] = useState('');

    useEffect(()=>{
        setPropertyData(props.property_data);
    }, [props.property_data]);

    var price_paid_date = property_data && property_data.price_paid_date ? dateFormat(property_data.price_paid_date, "d/mm/yyyy") : '';
    var features = property_data && property_data.features && Object.keys(property_data.features).length;
    

  return (
    <div className="card-block">
        <h3>Property Details:</h3>
        {
        property_data && (
            <ul className="mt-3">
            {property_data.address && <li><strong>Address:</strong><br />{property_data.address}</li>}

            {property_data.previously_advertised_price > 0 && <li><strong>Previously Advertised Price:</strong><br />{'£'+property_data.previously_advertised_price}</li>}

            {property_data.price_expectancy > 0 && <li><strong>Client Price Expectancy:</strong><br />{'£'+property_data.price_expectancy}</li>}

            {property_data.price_paid > 0 && <li><strong>Price Paid for the Property:</strong><br />{'£'+property_data.price_paid} {price_paid_date ? 'on '+price_paid_date : ''}</li> }

            {property_data.last_prestonBaker_valution_price > 0 && <li><strong>Last Preston Baker Valuation Price:</strong><br />{'£'+property_data.last_prestonBaker_valution_price} {property_data.last_prestonBaker_valution_date ? 'on '+property_data.last_prestonBaker_valution_date : ''}</li>}

            {property_data.model_price > 0 && <li><strong>Automated Valuation Model Price:</strong><br />{'£'+property_data.model_price}</li>}

            {property_data.rental_estimation > 0 && <li><strong>Rental Estimation:</strong><br />{'£'+property_data.rental_estimation+" per week"} </li>}

            {property_data.bedrooms > 0 && <li><strong>Bedrooms:</strong><br />{property_data && property_data.bedrooms}</li>}

            <li><strong>Bathrooms:</strong><br />-</li>

            {property_data.reception && <li><strong>Reception Rooms:</strong><br />{property_data.reception}</li>}

            { features > 0 && 
                <li><strong>Features:</strong><br /> 
                {
                Object.keys(property_data.features).map((key, index) => {
                    return(
                    <>
                        {property_data.features[key]}<br />
                    </>                                                      
                    )
                })
                }
                </li>
            }

            <li><strong>Amenities:</strong><br />-</li>
            <li><strong>EPC (13/08/20):</strong><br />-</li>
            <li><strong>Previous Floorplan:</strong><br /><a href="javascript:;">-</a></li>

            {property_data.description && <li><strong>Description:</strong><br />{property_data.description}</li>}

            <li><strong>Conservation Area:</strong><br />-</li>
            <li><strong>Planning Area:</strong><br />-</li>

            {property_data.area && <li><strong>Area (sq ft):</strong><br />{property_data.area}</li>}
        </ul>
        )
        }
    
    </div>
  )
}

export default PropertyDetails