import React, {useState, useEffect} from "react"
import {Container,Table, Dropdown, Row, Col, Button} from 'react-bootstrap';
import { Link, navigate } from "gatsby";
import dateFormat from 'dateformat';

import LeftNav from "../components/Common/leftNav";
import SEO from "../components/seo"
import PropertyRightSide from "../components/modules/property-right-side"
import PropertyTab from "../components/modules/property-tab"

import { useContainerContext } from "../store/ContainerContext";
import { PROPERTY_DETAILS } from "../queries/common_use_query";

import Property_Details from "../components/modules/property_details"

const PropertyDetails = (props) => {
  let path_name = (props.location.pathname).split('/');
  let get_property_id = path_name[path_name.length-1];

  const context = useContainerContext();
  const { property_data } = context.state;
  const { propertyDetDispatch } = context.actions;
  const { loading, error, data } = PROPERTY_DETAILS(get_property_id);

  useEffect(() => {
      propertyDetDispatch(data && data.properties && data.properties.length > 0 && data.properties[0]);
  }, [data]);

//   useEffect(() => {
//     //const { loading, error, data } = PROPERTY_DETAILS(get_property_id);
//     console.log('property_data 2', data);
//     // if(data && data.properties && data.properties.length > 0){
//     //   propertyDetDispatch(data.properties[0]);
//     // }
    
// }, [get_property_id]);

  

  //console.log('property_data 1', property_data);

  //console.log('property_data 2', data);

  
  
  return(
    <>
      <SEO title="Property Details" description="Property Details" />
      <div className="dashboard-wrapper">
        <LeftNav page_name="Property Details" />
        {/* sidebar */}

        <div className="dashboard-content seller_cnt">
          <Container>
              <div className="content-wrapper ">

                    {property_data && <PropertyTab tab_name="Property Details" seller_email={property_data.seller_email} get_property_id={property_data.id} />}
                    {/* Tab Content */}

                    <div className="sell_details">
                        <Row>
                            <Col lg={12} md={12}>
                                <Row>
                                    <Col lg={8} md={8}>
                                        <Property_Details property_data={property_data} />
                                    </Col>

                                    <PropertyRightSide property_id={get_property_id} />
                                </Row>
                            </Col>    
                        </Row>
                    </div>

            </div>

              
          </Container>
          {/* container */}
        </div>
        {/* dashboard-content */}
      </div>
    </>
  )

}

export default PropertyDetails